import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import geo from './map'
import post from './post'
import buildGroup from './group'
import home from './home'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    router,
    geo,
    post,
    buildGroup,
    home,
  }
})
