export default [
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../pages/setting/index.vue'),
    meta: {
      title: '设置'
    }
  },
  {
    path: '/person',
    name: 'person',
    component: () => import('../pages/setting/person.vue'),
    meta: {
      title: '个人信息',
      notKeepAlive: true
    }
  },
  {
    path: '/infoCenter',
    name: 'infoCenter',
    component: () => import('../pages/setting/infoCenter.vue'),
    meta: {
      title: '消息中心'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../pages/setting/aboutUs.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/suggest',
    name: 'suggest',
    component: () => import('../pages/setting/suggest.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/joinCompany',
    name: 'joinCompany',
    component: () => import('../pages/setting/joinCompany.vue'),
    meta: {
      title: '加入公司'
    }
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('../pages/setting/qrcode.vue'),
    meta: {
      title: '邀请二维码'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../pages/setting/admin.vue'),
    meta: {
      title: '管理后台'
    }
  },

  {
    path: '/groupSetting',
    name: 'groupSetting',
    component: () => import('../pages/setting/groupSetting.vue'),
    meta: {
      title: '群动态管理',
      notKeepAlive: true
    }
  },

  {
    path: '/companyUser',
    name: 'companyUser',
    component: () => import('../pages/setting/companyUser.vue'),
    meta: {
      title: '人员管理',
      notKeepAlive: true
    }
  },
]