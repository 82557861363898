<template>
  <transition-group name='fade' tag='div' class="page__Content">
    <div class="pageContent_Content page_success" v-show="state=='success'" :class='classWrap' :style='styleWrap' :key="0">
      <section class="pageContent_copntain" ref='pageContent_contain'>
        <slot></slot>
      </section>
      <div class="pageContent_footer" v-if="footer>0" :style="{height: `${footer}px`}" :key='3'>
        <slot name='footer'></slot>
      </div>
    </div>
    <div class="pageContent_Content" v-show="state=='loading'" :style="{background: '#fff'}" :key="1">
      <slot name='loading'>
        <loading-page></loading-page>
      </slot>
    </div>
    <div class="pageContent_Content" v-show="state=='error'" :style="{background: '#fff'}" :key="2">
      <slot name='fail'>
        <fail-page :tip="tip"></fail-page>
      </slot>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: 'page',
  components: {
    LoadingPage: () => import('@/components/page/loading.vue'),
    FailPage: () => import('@/components/page/fail.vue')
  },
  props: {
    footer: {
      type: Number,
      default: 0
    },
    classWrap: {
      type: [Array, Object]
    },
    styleWrap: Object,
    state: {
      type: String,
      default: 'success'
    },
    tip: {
      type: String,
      default: '好像哪里出错了，返回看看'
    }
  }
}
</script>

<style scoped lang="less">
.full_pages {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page__Content {
  .full_pages;
  &>div {
    .full_pages;
  }
}
.page_success {
  background: #fff
}
.pageContent_Content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
  .pageContent_copntain {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling : touch;
  }
  .pageContent_footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 60px;
  }
}

.fade-enter-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
