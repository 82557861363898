<template>
  <div id="app">
    <transition :name='"page-"+get_router'>
      <keep-alive>
        <router-view v-if='!$route.meta.notKeepAlive' />
      </keep-alive>
    </transition>
    <transition :name='"page-"+get_router'>
      <router-view v-if='$route.meta.notKeepAlive' />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonApi from '@/api/other'

export default {
  computed: {
    ...mapGetters([
      'get_router'
    ])
  },
  methods: {
    getConfig() {
      commonApi.appConfig({}).then(res => {
        if (res.data) {
          this.$global.setItem('appConfig', res.data)
        }
      })
    }
  },
  mounted() {
    window.vm = this
    this.getConfig()
  }
}
</script>

<style lang="less">
@import url('~@/common/css/transition.less');
@import url('~@/common/css/flex.less');
@import url('~@/common/css/page.less');
@import url('~@/common/css/init.less');
@import url('~@/common/css/viewer.less');
@search-padding: 0;
.pre (@style, @value){
  -webkit-@{style}: @value;
     -moz-@{style}: @value;
      -ms-@{style}: @value;
       -o-@{style}: @value;
          @{style}: @value;
}
.margin_auto {
  display: block;
  margin: 0 auto;
}
#app {
  position: absolute;
  top: 0;
  left: 0;
  // left: 50%;
  // transform: translateX(-50%);
  box-shadow: 0 0 15px #eee;
  // right: 0;
  // bottom: 0;
  // max-width: 600px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  &>div {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.underline {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: #eee;
    transform: translateY(.5)
  }
}

.underlineMargin {
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

.underlineFull {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 300%;
    height: 1px;
    bottom: 0;
    left: -50%;
    background: #eee;
    transform: translateY(.5)
  }
}

.transform {
  // row 居中
  &_r_c {
    left: 50%;
    .pre(transform, translateX(-50%));
    transform+_: translateX(-50%);
  }
  // column 居中
  &_c_c {
    top: 50%;
    .pre(transform, translateY(-50%));
    transform+_: translateY(-50%);
  }
  // row & column 居中
  &_c {
    top: 50%;
    left: 50%;
    .pre(transform, translate(-50%, -50%));
    transform+_: translate(-50%, -50%);
  }
}

@c: #4F92E6;
.theme {
  &_c {
    color: @c !important;
  }
  &_bg {
    background: @c;
    color: #fff;
  }
  &_linear {
    background: linear-gradient(90deg, #4AB2FF 0, #5382EE 100%);
  }
  &_dis_bg {
    background: #A7C8F2;
    color: #fff;
  }
  &_danger_bg {
    background: #d43f33;
    color: #fff;
  }
}
.pos {
  &_r {
    position: relative;
  }
  &_a {
    position: absolute;
  }
  &_f {
    position: fixed;
  }
}

.btn_base {
  border: none;
  outline: none;
  font-size: 16px;
  height: 44px;
  &:active {
    opacity: 0.8;
  }
}
.btn {
  .btn_base;
  &_radius {
    border-radius: 60px;
    .btn_base;
  }
}
.van-tabs__line {
  bottom: 26px !important;
}
.van-index-anchor--sticky {
  left: 0 !important;
}
.act_link {
  &:active {
    color: #dd4823;
    opacity: 0.8;
  }
}
.search {
  width: 100%;
  position: sticky; 
  height: 48px;
  top: 0; 
  z-index: 9; 
  background: #fff
}
.postIcon {
  position: fixed;
  bottom: 70px;
  left: 50%;
  width: 50px;
  height: 50px;
}
.text_line1 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
}
.footerPanel {
  height: 60px;
}
.amap-geolocation {
  top: 60px !important;
  left: 15px !important;
}

.van-pull-refresh {
  user-select: text !important;
  -ms-user-select: text !important;
  -webkit-user-select: text !important;
}
</style>
