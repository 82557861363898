
export default {
  state: {
    refresh: null,
    searchWord: "",
  },
  mutations: {
    SET_REFRESH (state, t) {
      state.refresh = t
    },
    SET_SEARCHWORD (state, t) {
      state.searchWord = t
    }
  },
  actions: {
    set_refresh (state, t) {
      state.commit('SET_REFRESH', t)
    },
    set_searchWord (state, t) {
      state.commit('SET_SEARCHWORD', t)
    }
  },
  getters: {
    get_refresh (state) {
      return state.refresh
    },
    get_searchWord (state) {
      return state.searchWord
    }
  }
}