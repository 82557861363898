const debounce = {
  debounce(fn, wait, _id) {
    const t = _id || '__Debounce_timer'
    const _wait = wait || 300
    if (window[t]) {
      clearTimeout(window[t])
      window[t] = null
    }
    window[t] = setTimeout(()=> {
      fn()
    }, _wait)
  },
  throttle(fn, wait, id) {
    const timer = id || '__Throttle_timer'
    const _wait = wait || 300
    const now = +new Date()
    if (!window[timer] || now - window[timer] > _wait) {
      fn()
      window[timer] = now
    }
  }
}

export default debounce