export default [
  {
    path: '/build/chooseGroup',
    name: "chooseGroup",
    component: ()=> import ('../pages/build/chooseGroup.vue'),
    meta: {
      title: '建群'
    }
  },
  {
    path: '/build/chooseConact',
    name: "chooseConact",
    component: ()=> import ('../pages/build/chooseConact.vue'),
    meta: {
      title: '选择联系人',
      notKeepAlive: true
    }
  },
  {
    path: '/build/chooseConactM',
    name: "chooseConactM",
    component: ()=> import ('../pages/build/chooseConactM.vue'),
    meta: {
      title: '选择联系人',
      notKeepAlive: true
    }
  },
  {
    path: '/build/labelManage',
    name: "labelManage",
    component: ()=> import ('../pages/build/labelManage.vue'),
    meta: {
      title: '标签管理',
      notKeepAlive: true
    }
  },
  {
    path: '/build/styleNum',
    name: "styleNum",
    component: ()=> import ('../pages/build/styleNum.vue'),
    meta: {
      title: '选择款号',
      notKeepAlive: true
    }
  }
]