const storage = {
  getItem(key, flag) {
    const storage = !flag ? 'localStorage' : 'localStorage'
    const val = window[storage].getItem(key)
    let _val = val
    if (/^[{|[]/.test(val)) {
      try {
        _val = JSON.parse(val)
      } catch (e) {
        _val = val
      }
    }
    return _val
  },
  setItem(key, val, flag) {
    console.log("golbal setItem", key, val)
    const storage = !flag ? 'localStorage' : 'localStorage'
    if (!val && typeof key === 'object') {
      for(let i in key) {
        if (i && key[i] && i !== '__proto__') {
          this.setItem(i, key[i])
        }
      }
    }
    const t = typeof val === 'object' && val
    if(t) {
      window[storage].setItem(key, JSON.stringify(val))
    } else if(val) {
      window[storage].setItem(key, val)
    }
  },
  removeItem(key, flag) {
    const storage = !flag ? 'localStorage' : 'localStorage'
    window[storage].removeItem(key)
  }
}

export default storage
