export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/index.vue'),
    meta: {
      title: '登录',
      notKeepAlive: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/login/register.vue'),
    meta: {
      title: '注册',
      notKeepAlive: true
    }
  },
  {
    path: '/register/pwd',
    name: 'registerPwd',
    component: () => import('../pages/login/registerPwd.vue'),
    meta: {
      title: '设置登录密码',
      notKeepAlive: true
    }
  },
  {
    path: '/login/bind',
    name: 'loginBind',
    component: () => import('../pages/login/bindPhone.vue'),
    meta: {
      title: '绑定手机号',
      notKeepAlive: true
    }
  },
  {
    path: '/login/pwd',
    name: 'loginPwd',
    component: () => import('../pages/login/login.vue'),
    meta: {
      title: '登录',
      notKeepAlive: true
    }
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('../pages/login/forget.vue'),
    meta: {
      title: '验证手机号',
      notKeepAlive: true
    }
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component: () => import('../pages/login/resetPwd.vue'),
    meta: {
      title: '重置密码',
      notKeepAlive: true
    }
  },
  {
    path: '/changePwd',
    name: 'changePwd',
    component: () => import('../pages/login/changePwd.vue'),
    meta: {
      title: '修改登录密码',
      notKeepAlive: true
    }
  },
  {
    path: '/editName',
    name: 'editName',
    component: ()=> import('../pages/login/editName.vue'),
    meta: {
      title: '编辑姓名'
    }
  }
]