
export default {
  state: {
    geoInfo: null
  },
  mutations: {
    SET_GEO (state, geo) {
      state.geoInfo = geo
    }
  },
  actions: {
    set_geo (state, geo) {
      state.commit('SET_GEO', geo)
    }
  },
  getters: {
    get_geo (state) {
      return state.geoInfo
    }
  }
}