export default [
  {
    path: '/post',
    name: 'post',
    component: ()=> import ('../pages/post/index.vue'),
    meta: {
      title: '发布',
      //notKeepAlive: true
    }
  },
  {
    path: '/post/choose',
    name: 'postChoose',
    component: ()=> import ('../pages/post/choose.vue'),
    meta: {
      title: '选择@的人',
      notKeepAlive: true
    }
  },
  {
    path: '/post/deleteMember',
    name: 'deleteMember',
    component: ()=> import ('../pages/post/deleteMember.vue'),
    meta: {
      title: '删除成员',
      notKeepAlive: true
    }
  },
  {
    path: '/post/label',
    name: 'postLabel',
    component: ()=> import ('../pages/post/label.vue'),
    meta: {
      title: '选择标签',
    }
  },
  {
    path: '/post/createLabel',
    name: 'createLabel',
    component: ()=> import ('../pages/post/createLabel.vue'),
    meta: {
      title: '编辑标签',
    }
  },
  {
    path: '/post/styNum',
    name: 'postStyNum',
    component: ()=> import ('../pages/post/styNum.vue'),
    meta: {
      title: '编辑款号',
      notKeepAlive: true
    }
  },
  {
    path: '/postList',
    name: 'postList',
    component: ()=> import ('../pages/post/postList.vue'),
    meta: {
      title: '查询帖子',
      notKeepAlive: true
    }
  }
]
