
export default {
  state: {
    noticeMsg: '',
    noticeStatus: true,
    styleNum: null, // 款号
    labelList: [], // 标签
    anchorList: [], // 提醒人
    anchorType: null, // 1=》无  2=》部分   3=》全部
    postStatus: null,
    postAnchorListMap: {},
    postAnchorPage: null,  //@发起页面
    updateContent: '',
  },
  mutations: {
    SET_STYLENUM (state, stylenum) {
      state.styleNum = stylenum
    },
    SET_NOTICESTATUS(state, status) {
      state.noticeStatus = status
    },
    SET_LABELlIST(state, labelList) {
      state.labelList = labelList
    },
    SET_ANCHORLIST(state, anchor) {
      state.anchorList = anchor
    },
    SET_NOTICEMSG(state, msg) {
      state.noticeMsg = msg
    },
    SET_ANCHORTYPE(state, type) {
      state.anchorType = type
    },
    SET_POSTSTATUS(state, status) {
      state.postStatus = status
    },
    SET_POST_ANCHORLISTMAP(state, anchor) {
      state.postAnchorListMap = anchor
    },
    SET_POST_ANCHORPAGE(state, anchor) {
      state.postAnchorPage = anchor
    },
    SET_UPDATE_CONTENT(state, content) {
      state.updateContent = content
    },
  },
  actions: {
    set_styleNum (state, stylenum) {
      state.commit('SET_STYLENUM', stylenum)
    },
    set_labelList(state, labelList) {
      state.commit('SET_LABELlIST', labelList)
    },
    set_anchorList(state, anchor) {
      state.commit('SET_ANCHORLIST', anchor)
    },
    set_noticeMsg(state, msg) {
      state.commit('SET_NOTICEMSG', msg)
    },
    set_noticeStatus(state, status) {
      state.commit('SET_NOTICESTATUS', status)
    },
    set_anchorType(state, type) {
      state.commit('SET_ANCHORTYPE', type)
    },
    set_postStatus(state, status) {
      state.commit('SET_POSTSTATUS', status)
    },
    set_postAnchorListMap(state, anchor) {
      state.commit('SET_POST_ANCHORLISTMAP', anchor)
    },
    set_postAnchorPage(state, anchor) {
      state.commit('SET_POST_ANCHORPAGE', anchor)
    },
    set_updateContent(state, content) {
      state.commit('SET_UPDATE_CONTENT', content)
    },
  },
  getters: {
    get_styleNum (state) {
      return state.styleNum
    },
    get_labelList(state){
      return state.labelList
    },
    get_anchorList(state) {
      return state.anchorList
    },
    get_noticeMsg(state) {
      return state.noticeMsg
    },
    get_anchorType(state) {
      return state.anchorType
    },
    get_postStatus(state) {
      return state.postStatus
    },
    get_noticeStatus(state) {
      return state.noticeStatus
    },
    get_postAnchorListMap(state) {
      return state.postAnchorListMap
    },
    get_postAnchorPage(state) {
      return state.postAnchorPage
    },
    get_updateContent(state) {
      return state.updateContent
    },
  }
}