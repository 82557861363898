<template>
  <div>
    <div class="f_flex f_a_c headBar" :style="{height: height+'px'}" :class="{fixed: isFixed}">
      <div class="leading f_flex f_a_c">
        <slot name='leading'>
          <div @click="goBack">
            <i style="font-size: 20px" class="iconfont icon-back"></i>
          </div>
        </slot>
      </div>
      <div class="title f_flex f_a_c f_j_c">
        <slot>{{titleValue}}</slot>
      </div>
      <div class="action f_flex f_a_c">
        <slot name='action'></slot>
      </div>
    </div>
    <div v-if='isFixed' :style="{height: height+'px'}" class="fixedPanel"></div>
  </div>
</template>

<script>
export default {
  data: ()=> ({
    titleValue: ''
  }),
  props: {
    title: String,
    showTitle: {
      type: Boolean,
      default: true
    },
    isFixed: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 44
    }
  },
  watch: {
    title(n) {
      this.titleValue = n
    }
  },
  methods: {
    goBack() {
      const h = this.$global.getItem('routerHistory', )||[]
      // console.log(h)
      if (h.length > 1) {
        this.$router.back()
      } else {
        this.$router.replace('/home')
      }
    }
  },
  mounted() {
    this.titleValue = this.title || this.$route.meta.title
    this.$route.meta.change = ()=> {
      this.titleValue = this.title || this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.headBar {
  height: 44px;
  width: 100%;
  background: #fff;
  padding: 0 12px;
  font-size: 14px;
  box-sizing: border-box;
  z-index: 9;
  .leading {
    position: absolute;
    height: 100%;
    left: 12px;
  }
  .action {
    position: absolute;
    height: 100%;
    right: 12px;
  }
  .title {
    position: absolute;
    width: 80%;
    left: 10%;
    text-align: center;
  }
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.fixedPanel {
  position: relative;
  height: 44px;
}
</style>
