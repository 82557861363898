import http from './http'

import Config from './config';
const apiHeader = Config.apiUrl
export default {
  // 短信下发接口
  send: (data) => http.post(`${apiHeader}/sms/send`, {data}),
  // 意见反馈接口
  feedback: data => http.post(`${apiHeader}/session/feedback/create`, {data}),
  // 系统启动配置接口
  queryAppBootConfig: data => http.post(`${apiHeader}/config/queryAppBootConfig`, {data}),
  // 消息中心分页查询接口
  noticeList: data => http.post(`${apiHeader}/session/notice/pageQueryList`, {data}),
  // appConfig
  appConfig: data => http.post(`${apiHeader}/config/queryAppBootConfig`, {data}),
  // 是否阅读
  readDetailList: data => http.post(`${apiHeader}/session/posts/readDetailUserList`, {data})
}