
export default {
  state: {
    build_company: null, // 建群信息  公司
    build_contact: null, //
    groupSearchList: []
  },
  mutations: {
    SET_GROUP_COMPANY(state, build_company) {
      state.build_company = build_company
    },
    SET_GROUP_CONTACT(state, conact) {
      state.build_contact = conact
    },
    SET_GROUP_SEARCH_LIST(state, list) {
      state.groupSearchList = list
    }
  },
  actions: {
    set_group_company (state, build_company) {
      state.commit('SET_GROUP_COMPANY', build_company)
    },
    set_group_contact (state, build_contact) {
      state.commit('SET_GROUP_CONTACT', build_contact)
    },
    set_groupSearchList(state, list) {
      state.commit('SET_GROUP_SEARCH_LIST', list)
    }
  },
  getters: {
    get_groupInfo (state) {
      return state.build_company
    },
    get_contact(state) {
      return state.build_contact
    },
    get_groupSearchList(state) {
      return state.groupSearchList
    }
  }
}