export default [
  {
    path: '/group',
    name: 'group',
    component: () => import('../pages/group/index.vue'),
    meta: {
      title: '群'
    }
  },
  {
    path: '/group/choose',
    name: 'choose',
    component: () => import('../pages/group/choose.vue'),
    meta: {
      title: '选择@的人'
    }
  },
  {
    path: '/group/close',
    name: 'groupClose',
    component: () => import('../pages/group/close.vue'),
    meta: {
      title: '关闭的群',
      notKeepAlive: true
    }
  },
  {
    path: '/group/changeName',
    name: 'changeName',
    component: () => import('../pages/group/changeName.vue'),
    meta: {
      title: '修改群名称',
      notKeepAlive: true
    }
  },
  {
    path: '/group/rangeTime',
    name: 'rangeTime',
    component: () => import('../pages/group/rangeTime.vue'),
    meta: {
      title: '看帖时间范围'
    }
  },
  {
    path: '/group/info',
    name: 'groupInfo',
    component: () => import('../pages/group/info.vue'),
    meta: {
      title: '群信息',
      notKeepAlive: true
    }
  },

  {
    path: '/group/manager',
    name: 'groupManager',
    component: () => import('../pages/group/manager.vue'),
    meta: {
      title: '群助手',
      notKeepAlive: true
    }
  },

  {
    path: '/group/managerAdd',
    name: 'groupManagerAdd',
    component: () => import('../pages/group/managerAdd.vue'),
    meta: {
      title: '添加群助手',
      notKeepAlive: true
    }
  },


  {
    path: '/group/managerDelete',
    name: 'groupManagerDelete',
    component: () => import('../pages/group/managerDelete.vue'),
    meta: {
      title: '删除群助手',
      notKeepAlive: true
    }
  },


  {
    path: '/group/detail',
    name: 'groupDetail',
    component: () => import('../pages/group/detail.vue'),
    meta: {
      title: '群'
    }
  },
  {
    path: '/group/search',
    name: 'groupSearch',
    component: () => import('../pages/group/search.vue'),
    meta: {
      title: '搜索',
    }
  },
]
