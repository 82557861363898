const interceptor = (to, from) => {
  let routerHistory = sessionStorage.getItem('routerHistory')
  let step = 1 // 1=> go    -1=> goback   0=> no
  let h = JSON.parse(routerHistory) || []
  if (h.length) {
    if (to.name !== h[h.length - 1]) { // 前往页面不同当前页面
      if (h.length === 1) {
        h.push(to.name)
        step = 1
      } else {
        if (h.length > 1) {
          if (h[h.length - 2] !== to.name) { // 前往页面不同于前一个页面 判断为前进
            h.push(to.name)
            step = 1
          } else {
            h.pop()
            step = -1
          }
        }
      }
    } else {
      step = 0
    }
  } else {
    if (to.name !== h[h.length - 1]) {
      step = 0
      if (from.name || from.name === null) {
        h.push(to.name)
      } else {
        h.push(from.name)
        h.push(to.name)
      }
    }
  }
  sessionStorage.setItem('routerHistory', JSON.stringify(h))
  return step
}

export default interceptor


export const routerDirection = (name) => {
  window.sessionStorage.setItem('directPage',name)
  setTimeout(()=> {
    window.sessionStorage.removeItem('directPage')
  }, 300)
}
