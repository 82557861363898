export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('../pages/home/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../pages/home/detail.vue'),
    meta: {
      title: '详情',
      notKeepAlive: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../pages/home/search.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../pages/map/index.vue'),
    meta: {
      title: '定位',
      notKeepAlive: true
    }
  },
  {
    path: '/hasRead',
    name: 'hasRead',
    component: () => import('../pages/home/readPage.vue'),
    meta: {
      title: '查看详情',
      notKeepAlive: true
    }
  },
]
