import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from '@/common/js/global'
import storage from '@/common/js/storage'
import debounce from '@/common/js/debounce'
import url from '@/common/js/url'
import HeaderBar from '@/components/headerBar/index.vue'
import { Toast, Dialog, Notify, Lazyload, Checkbox, Button, Overlay } from 'vant';
import '@vant/touch-emulator';
import page from '@/components/page/index.vue'
import Load from '@/components/Load'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
// console.log('log: 2020-12-29');

import { Col, Row } from 'vant';

import { Popup, ActionSheet } from 'vant';
import { Cell, CellGroup } from 'vant';

import { Switch } from 'vant';

import { Picker } from 'vant';

import { Tab, Tabs } from 'vant';

import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx;

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Picker);
Vue.use(Switch);

Vue.use(Cell);
Vue.use(CellGroup);

Vue.use(Popup);
Vue.use(Col);
Vue.use(Row);

Vue.use(Viewer)
Vue.use(Lazyload);
Vue.component('page', page)
Vue.component('header-bar', HeaderBar)

Vue.use(Overlay);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Load);
Vue.use(ActionSheet);
Vue.prototype.$global = global.mixins(storage, debounce, url)

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
